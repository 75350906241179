<template>
    <ModalContent name="fieldBlocksModal" ref="modal" width="42%" v-bind="$attrs" v-on="$listeners" :showFooter="false">
        <template v-if="field" #title> {{ field.name }} Blocks </template>
        <template #content>
            <div v-if="isLoading" class="h-96 flex items-center justify-center"><Loader /></div>
            <div v-else class="h-96 overflow-y-auto scrol-bar">
                <ListView :listItems="blocks" :showSearch="true" @handleFilterResult="$emit('handleFilterResult', $event)">
                    <template #item="{ item }">
                        <router-link
                            :is="!checkPermission('field.read') ? 'span' : 'router-link'"
                            :to="{
                                name: 'block-admin-details',
                                params: { id: item.id ,action:'edit'},
                            }"
                            @click.native="handleRouterLinkClick(item)"
                            class="text-primary w-full truncate"
                            :title="item.name"
                            >{{ item.name }}</router-link
                        >
                    </template>
                </ListView>
            </div>
        </template>
    </ModalContent>
</template>

<script>
import ModalContent from "@shared/modal-content";
import ListView from "@shared/components/list-view/index.vue";
import Loader from "@shared/loader"
import { checkPermission } from "@shared/utils/functions"
import axios from "@/axios";
export default {
    name: "field-checks-modal",
    components: {
        ModalContent,
        ListView,
        Loader
    },
    props: {
        field: {
            type: Object,
            default: () => {},
        },
        blocks: {
            type: Array,
            default: () => [],
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {};
    },
    computed: {},
    methods: {
        checkPermission,
        async handleRouterLinkClick(item) {
        try {
                let payload = { lock_status: true }
                let url = `/blocks/locked/${item.id}`;
                await axios.post(url, payload);
                return true;
            } catch (error) {
                this.$toast.error(error.response.data.detail || "Failed to lock this block");
                return false; // Return false when there's an error
            }
    }
}
};
</script>

<style lang="scss" scoped></style>
